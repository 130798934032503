<template>
  <section>
    <template
      v-if="subscription.allow_billets && subscription.iugu_activated && store_info.create_billets"
    >
      <template v-if="!$isMobile">
        <el-table v-loading="loading" :data="billetsReports.data" @sort-change="handleSort">
          <el-table-column
            :formatter="(row, column) => formatDate(row.due_date)"
            sortable
            prop="due_date"
            :label="billetsFilterType ? 'Pagamento' : 'Vencimento'"
            align="center"
            min-width="150"
          />
          <el-table-column align="center" prop="payer_name" sortable label="Nome" min-width="120">
            <template slot-scope="scope">
              <span
                class="name"
                @click="handleClientNavigation(scope.row.payer_id)"
              >{{ scope.row.payer_name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :formatter="(row, column) => $n(row.paid_amount, 'currency')"
            sortable
            prop="paid_amount"
            align="center"
            label="Valor pago"
            min-width="130"
          />
          <el-table-column
            :formatter="(row, column) => $n(row.amount, 'currency')"
            sortable
            prop="amount"
            align="center"
            label="Valor total"
            min-width="130"
          />
          <el-table-column prop="status" label="Status" align="center" min-width="140">
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="handleStatusColor(scope.row.status)"
              >{{ handleStatusLabel(scope.row.status) }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Ações" align="center" min-width="100">
            <template slot-scope="scope">
              <el-button
                align="center"
                size="mini"
                type="primary"
                @click="handleDetails(scope.row)"
              >Detalhes</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-else>
        <section class="custom-card-grid">
          <el-card v-for="report in billetsReports.data" :key="report.id">
            <div slot="header">
              <h2
                class="card-name"
                @click="handleClientNavigation(report.payer_id)"
              >{{ report.payer_name }}</h2>
            </div>
            <p class="card-info">
              {{billetsFilterType ? "Pagamento" : 'Vencimento'}}
              <b>{{formatDate(report.due_date)}}</b>
            </p>
            <p class="card-info">
              Valor pago:
              <b>{{$n(report.paid_amount, 'currency')}}</b>
            </p>
            <p class="card-info">
              Valor total:
              <b>{{$n(report.amount, 'currency')}}</b>
            </p>
            <p class="card-info">
              Status:
              <b>{{handleStatusLabel(report.status)}}</b>
            </p>
            <div class="custom-button-grid">
              <el-button size="mini" type="primary" @click="handleDetails(report)">Detalhes</el-button>
            </div>
          </el-card>
        </section>
      </template>
      <div class="pagination-wrapper">
        <el-pagination
          :total="billetsReports.total_pages * 10"
          :pages-sizes="[10,20,30,40,50]"
          :current-page.sync="activePage"
          background
          layout="sizes,prev, pager, next"
          @current-change="handlePagination"
          @size-change="handleBilletsPaginationSize"
        />
      </div>
      <el-dialog
        :visible.sync="showModal"
        class="billet-reports-modal"
        :fullscreen="$isMobile ? true : false"
      >
        <h2 style="font-weight: bold;">Linha digitavel</h2>
        <h3>{{ selectedBillet.barcode }}</h3>
        <el-button
          v-if="selectedBillet.url"
          size="small"
          @click="handleLink(selectedBillet.url)"
        >Abrir link</el-button>
        <el-button
          v-if="selectedBillet.barcode"
          size="small"
          type="primary"
          @click="copyToClipboard(selectedBillet.barcode)"
        >Copiar código</el-button>
      </el-dialog>
      <el-dialog :visible.sync="showBilletsDialog">
        <h3>Boletos</h3>
        <el-table :data="activeBillets" style="width: 100%;">
          <el-table-column
            :formatter="
						(row, column) => $d(new Date(row.due_date), 'sale_summary')
					"
            sortable
            align="center"
            prop="due_date"
            label="Venc."
          />
          <el-table-column
            :formatter="(row, column) => $n(row.amount, 'currency')"
            sortable
            align="center"
            prop="amount"
            label="Valor"
          />
          <el-table-column prop="actions" label="Ações">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-printer"
                @click="downloadBillet(scope.row.url)"
              >Baixar</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </template>
    <template v-else-if="!subscription.allow_billets || !subscription.iugu_activated">
      <section class="cannot-access">
        <h2
          :class="{notAllowed: !subscription.allow_billets}"
        >Você precisa fazer o upgrade do seu plano para emitir boletos para os seus clientes</h2>
        <br />
        <el-button type="success" @click="$router.push('/assinar-plano')">Ver planos</el-button>
      </section>
    </template>
    <template v-else-if="!store_info.create_billets">
      <section class="cannot-access">
        <h2
          :class="{notAllowed: !subscription.allow_billets}"
        >Para utilizar essa funcionalidade por favor ative a emissão de boletos nas configurações.</h2>
        <br />
        <el-button type="success" @click="$router.push('/configuracoes/boletos')">Configurações</el-button>
      </section>
    </template>
  </section>
</template>

<script>
import * as types from "@/store/types";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    rangeDates: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    billetStatus: {
      type: String,
      default: "all"
    },
    billetsFilterType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
      selectedBillet: {},
      activePage: 1,
      order_by: "date_desc",
      showBilletsDialog: false,
      activeBillets: [],
      billetsPageSize: 10,
      billetsPage: 1
    };
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      store_id: types.AUTH_ACTIVE_STORE,
      subscription: types.SUBSCRIPTION_CONFIG,
      billetsReports: types.REPORTS_BILLETS,
      store_info: types.STORE_INFO
    })
  },
  watch: {
    activePage: function(page) {
      this.$emit("page", page);
    }
  },
  async created() {
    const today = new Date();
    const start = new Date(today.setHours(0, 0, 0));
    const end = new Date(today.setHours(23, 59, 59));
    // this.rangeDates = { start, end };
    this.loadingReports = true;
    const billetsFilterTypeDate = this.billetsFilterType;
    const page_size = 10;
    await this.$store
      .dispatch(types.REPORTS_BILLETS, {
        store_id: this.store_id,
        start,
        end,
        page_size,
        billetsFilterTypeDate
      })
      .catch(error => {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({
          title: "Ops...",
          message: "Não foi possível recuperar os relatorios de boletos."
        });
      });
    this.loadingReports = false;
  },
  methods: {
    showBillets(billets) {
      this.showBilletsDialog = true;
      this.activeBillets = billets;
    },
    async handleClientNavigation(id) {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage(id);
      }
      await this.$store.dispatch(types.CLIENT_RESET);
      this.$router.push({
        name: "Cliente",
        params: { clientID: id }
      });
    },
    async handleSort({ prop, order }) {
      this.loadingReports = true;
      const { start, end } = this.rangeDates;
      switch (prop) {
        case "due_date":
          this.order_by = "date_";
          break;
        case "amount":
          this.order_by = "amount_";
          break;
        case "paid_amount":
          this.order_by = "amount_";
          break;
        default:
          this.order_by = "name_";
          break;
      }
      this.order_by += order === "ascending" ? "asc" : "desc";
      await this.$store
        .dispatch(types.REPORTS_BILLETS, {
          store_id: this.store_id,
          start_date: start,
          end_date: end,
          order_by: this.order_by,
          status: this.billetStatus
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({
            title: "Ops...",
            message: "Não foi possível recuperar os boletos dos clientes."
          });
        });
      this.loadingReports = false;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async handleLink(link) {
      window.open(link, "_blank");
    },
    copyToClipboard(barcode) {
      navigator.permissions
        .query({ name: "clipboard-write" })
        .then(async result => {
          if (result.state == "granted" || result.state == "prompt") {
            navigator.clipboard
              .writeText(barcode)
              .then(() =>
                this.$notify({
                  title: "Copiado!",
                  message: "Código copiado com sucesso",
                  type: "success"
                })
              )
              .catch(() =>
                this.$notify.error({
                  title: "Erro!",
                  message: "Não foi possivel copiar o código de barras"
                })
              );
          }
        });
    },
    handleDetails(billet) {
      this.showModal = true;
      this.selectedBillet = billet;
    },
    handleStatusLabel(status) {
      const labels = {
        registered: "Registrado",
        paid: "Pago",
        canceled: "Cancelado",
        expired: "Expirado"
      };
      return labels[status];
    },
    handleStatusColor(status) {
      const type = {
        registered: "warning",
        paid: "success",
        canceled: "danger",
        expired: "danger"
      };
      return type[status];
    },
    async handlePagination(page) {
      this.loadingReports = true;
      const { start, end } = this.rangeDates;
      this.billetsPage = page;
      const payload = {
        store_id: this.store_id,
        start,
        end,
        page,
        status: this.billetStatus,
        page_size: this.billetsPageSize,
        billetsFilterTypeDate: this.billetsFilterType
      };
      await this.$store
        .dispatch(types.REPORTS_BILLETS, payload)
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.loadingReports = false;
    },
    async handleBilletsPaginationSize(size) {
      const { start, end } = this.rangeDates;
      this.pageSize = size;
      this.loadingReports = true;
			this.billetsPageSize = size;
      const payload = {
        store_id: this.store_id,
        start,
        end,
        page_size: this.billetsPageSize,
        status: this.billetStatus,
        billetsFilterTypeDate: this.billetsFilterType
      };
      try {
        await this.$store.dispatch(types.REPORTS_BILLETS, payload);
        this.$emit("onBilletsChangePageSize", size);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loadingReports = false;
    },
    downloadBillet(url) {
      window.open(url, `_blank`);
    }
  }
};
</script>

<style lang="scss" scoped>
.billet-reports-modal {
  .el-dialog {
    .el-dialog__body {
      text-align: center;
      h3 {
        margin: 6px 0 32px 0;
      }
    }
  }
}
.pagination-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.relatorios {
  .el-table__row {
    td {
      .cell {
        color: $preto;
      }
    }
  }
  .el-tag {
    width: 100px;
    font-size: 13px !important;
  }
}
.cannot-access {
  text-align: center;
  h2 {
    text-align: center;
    margin-top: 2rem;
    b {
      font-weight: bold;
      color: $laranja;
    }
  }
  .notAllowed {
    font-weight: bold;
    color: $laranja;
  }
}
.card-name {
  font-weight: bold;
  color: $laranja;
  text-align: center;
}
.custom-card-grid {
  margin-top: 1rem;
  display: grid;
  grid: 1fr / 1fr 1fr;
  @media screen and (max-width: 527px) {
    grid: 1fr / 1fr;
  }
  grid-gap: 10px;

  .card-info {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $preto;
    margin-top: 5px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  .custom-button-grid {
    display: grid;
    grid: 1fr 1fr 1fr / 1fr;
    grid-gap: 5px;
    margin-top: 1rem;
    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
.el-pagination {
  @media screen and (max-width: 767px) {
    white-space: normal;
  }
}
</style>
