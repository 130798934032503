<template>
  <div class="relatorio">
    <desktop-filters
      :loading.sync="loadingReports"
      :show-download="true"
      :action="tab"
      :query="query"
      :page-size="pageSize"
      :reports-page="reportsPage"
      @onChangeDates="handleChangeDates"
    />
    <statements-reports
      :compute-summary="computeSummary"
      :format-date="formatDate"
      :handle-client-navigation="handleClientNavigation"
      :range-dates="rangeDates"
      @textQuery="val => sendQueryToFilter(val)"
    />
  </div>
</template>

<script>
import StatementsReports from "@/components/Reports/StatementsReports";
import DesktopFilters from "@/containers/Reports/DesktopFilters";
import moment from "moment";
import * as types from "@/store/types";

export default {
  components: {
    StatementsReports,
    DesktopFilters
  },
  data() {
    return {
      tab: "faturas",
      loadingReports: true,
      query: "",
      pageSize: 10,
      reportsPage: 1,
      rangeDates: {}
    };
  },
  computed: {},
  async mounted() {
    this.tab = "faturas";
    const today = new Date();
    const start = new Date(today.setHours(0, 0, 0));
    const end = new Date(today.setHours(23, 59, 59));
    this.rangeDates = { start, end };
  },
  methods: {
    sendPageSizeToFilter(size) {
      this.pageSize = size;
    },
    handleChangeDates(dates) {
      const { start, end } = dates;
      this.rangeDates = dates;
    },
    sendQueryToFilter(val) {
      this.query = val;
    },
    computeSummary({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        sums[index] = "";
        if (index === 0) {
          sums[index] = "Total";
          return;
        }
        if (
          column.property === "amount" ||
          column.property === "paid_amount" ||
          column.property === "total_amount" ||
          column.property === "total_remaining" ||
          column.property === "remaining_amount"
        ) {
          const values = data.map(it => it[column.property]);
          if (!values.length) return;
          sums[index] = this.$n(
            values.reduce((a, b) => +a + +b),
            "currency"
          );
        }
        if (column.label === "Saldo") {
          const values = data.map(it => it.paid_amount - it.total_amount);
          if (!values.length) return;
          sums[index] = this.$n(
            values.reduce((a, b) => +a + +b),
            "currency"
          );
        }
      });
      return sums;
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    async handleClientNavigation(id) {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage(id);
      }
      await this.$store.dispatch(types.CLIENT_RESET);
      this.$router.push({
        name: "Cliente",
        params: { clientID: id }
      });
    }
  }
};
</script>

<style lang="scss">
.relatorio {
  position: relative;
  margin: 1rem;
  .el-tabs__item {
    font-family: $title-font;
  }
  .el-table {
    .cell {
      word-wrap: normal;
      word-break: break-word;
    }
  }
  .collapse-header {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    align-items: center;
    padding-right: 14px;
    h2 {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      font-weight: 700;
    }
    .wrapper {
      display: inline-block;
      height: 48px;
      margin: 6px 0 0 12px;
      span {
        font-family: Montserrat;
        letter-spacing: 1.025px;
        text-align: center;
        font-size: 14px;
        display: block;
        line-height: 14px;
        font-weight: 300;
      }
      .content {
        font-family: Montserrat;
        letter-spacing: 1.025px;
        color: $laranja;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
      }
    }
  }
  .el-table {
    width: 100%;
  }
  .el-table__row td .cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .el-table__row td .cell .name {
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: $azul;
      text-decoration: underline;
    }
  }
  .el-table__footer-wrapper td {
    font-size: 15px;
    font-weight: bold !important;
  }
}
</style>
