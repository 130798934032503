<template>
  <section>
    <div class="center">
      <h1>{{ title }}</h1>
      <div >
       <iframe
            :src=url
            frameborder="0"
            width="98%"
            height="1000"
            allowtransparency
        ></iframe>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import * as types from "@/store/types";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      url: "",
      title: ""
    };
  },
  computed: {
    ...mapGetters({
      storeID: types.AUTH_ACTIVE_STORE
    })
  },
  methods: {
    handleClose() {
      this.hasPromotion = false;
      this.$router.push("/");
  },
    async getUrl() {
      const storeID = this.storeID;
      const reportId = this.$route.meta.params.report_id;
      const type = this.$route.meta.params.type;
      const response = await axios.get(
      `stores/${storeID}/reports/dynamic_report?type=${type}&report_id=${reportId}`
      );
      this.url = response.data.url
      this.title = this.$route.meta.params.title;
    },
  },
  mounted(){
    this.getUrl()
  }

};

</script>

<style lang="scss" scoped>
.title {
  text-transform: uppercase;
  font-weight: bold;
  color: $laranja;
  text-align: center;
}
.dialog-container {
  display: flex;
  margin-top: 1rem;
  justify-content: center;

  .responsive-image {
    max-height: 50vh;
    @media screen and (max-width: 767px) {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
.center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
</style>
