<template>
  <deals-reports />
</template>

<script>
import DealsReports from "@/containers/Reports/DealsReports";
export default {
  components: {
    DealsReports
  }
};
</script>
