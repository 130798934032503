<template>
  <main>
    <dynamicReports :key="$route.fullPath"/>
  </main>
</template>

<script>
import DynamicReports from "@/containers/Reports/DynamicReports";
export default {
  components: {
    DynamicReports
  }
};
</script>

<style>
</style>
