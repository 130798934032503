<template>
  <billets-reports />
</template>

<script>
import BilletsReports from "@/containers/Reports/BilletsReports";
export default {
  components: {
    BilletsReports
  }
};
</script>
