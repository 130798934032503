<template>
  <div class="payment-selector-list">
    <section
      v-if="statementsItens && statementsItens.length > 0"
      :class="{ active: data && data.length > 0}"
      class="header"
    >
      <div>
        <h5>
          Parcela
        </h5>
      </div>
      <div>
        <h5>Data</h5>
      </div>
      <div>
        <h5>Valor</h5>
      </div>
    </section>
    <section class="content">
      <sale-selector-list-item
        v-for="item in statementsItens"
        :key="item.id"
        :data="item"
        :future="future"
        :action="action"
        :reverse="reverse"
        @onClick="handleListItemClick"
      />
    </section>
    <section
      v-if="!hideTotal && statementsItens != false"
      class="footer">
      <h5>
        VALOR TOTAL
        <span>{{ formatMoney(total) }}</span>
      </h5>
    </section>
  </div>
</template>

<script>
import toMoney from "@/helpers/toMoney";
import SaleSelectorListItem from "./SaleSelectorListItem";

export default {
  components: {
    SaleSelectorListItem
  },
  props: {
    data: {
      type: [Array, null],
      default: () => {}
    },
    total: {
      type: [Number, null],
      default: () => 0
    },
    action: {
      type: [Boolean, null],
      default: false
    },
    future: {
      type: [Boolean, null],
      default: false
    },
    all: {
      type: [Boolean, null],
      default: false
    },
    hideTotal: {
      type: [Boolean, null],
      default: false
    },
    reverse: {
      type: [Boolean, null],
      default: false
    },
    deselectedItem: {
      type: [Object, null],
      default: () => null
    },
    futureItem: {
      type: [Object, null],
      default: () => null
    },
    statementId: {
      type: [String, null],
      default: ""
    },
    previousCondition: {
      type: [Array, null],
      default: () => null
    }
  },
  data() {
    return {
      interestItem: {},
      statementsItens: []
    };
  },
  watch: {
    data: function(data) {
      if (!this.reverse && this.data && this.data.length > 0) {
        this.statementsItens = this.statementsItens.concat(
          this.interestItem,
          data.map(item => ({
            ...item,
            statement_id: this.statementId,
            selected: false,
            future: this.future
          }))
        );
      }
      this.statementsItens = this.data;
    },
    all: function(newValue) {
      this.statementsItens = this.statementsItens.map(item => ({
        ...item,
        selected: newValue,
        statement_id: this.statementId,
      }));
    },
    deselectedItem: function(newValue) {
      if (!this.reverse && newValue) {
        const indexOf = this.statementsItens.findIndex(
          item => item.id === newValue.id
        );
        if (indexOf !== -1) {
          this.statementsItens[indexOf].selected = false;
          this.checkAll();
        }
      }
    },
    futureItem: function(newValue) {
      const indexOf = this.statementsItens.findIndex(
        val => val.id === newValue.id
      );
      if (indexOf !== -1) {
        this.statementsItens[indexOf].selected = !this.statementsItens[indexOf]
          .selected;
        this.checkAll();
      }
    }
  },
  mounted() {
    if (!this.reverse && this.data && this.data.length > 0) {
      if (Array.isArray(this.previousCondition)) {
        const interestItem = this.previousCondition.find(
          item => item.description === "Juros"
        );
        if (interestItem) {
          this.interestItem = {
            id: Math.random() * 100,
            ...interestItem,
            statement_id: this.statementId,
            selected: false,
            future: this.future
          };
        } else {
          this.interestItem = null;
        }
      }
      this.statementsItens = this.statementsItens.concat(
        this.interestItem.hasOwnProperty("amount") ? this.interestItem : [],
        this.data.map(item => ({
          ...item,
          statement_id: this.statementId,
          selected: false,
          future: this.future
        }))
      );
    }
  },
  methods: {
    formatMoney(amount) {
      if(amount <= 0) {
        return toMoney(0)
      }
      return toMoney(amount);
    },
    checkAll() {
      const index = this.statementsItens.findIndex(
        item => item.selected === true
      );
      if (index !== -1) {
        return this.$emit("disableAll", true);
      }
      this.$emit("disableAll", false);
    },
    handleListItemClick(item) {
      if (this.future) {
        return this.$emit("onClick", [item]);
      }
      const indexOf = this.statementsItens.findIndex(val => val.id === item.id);
      if (indexOf !== -1) {
        this.statementsItens[indexOf].selected = !this.statementsItens[indexOf]
          .selected;
      }
      this.checkAll();
      return this.$emit("onClick", item);
    }
  }
};
</script>


<style lang="scss">
.payment-selector-list {
  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 6px 60px 6px 0;
    h5 {
      font-family: Montserrat;
      font-weight: 700;
      color: $preto;
      font-size: 13px;
    }
    > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      h5:first-child {
        padding: 0 4px;
        min-width: 80px;
      }
      h5 + h5 {
        margin-left: 12px;
      }
    }
    @media screen and (max-width: 320px) {
      padding: 6px 14px 6px 0;
    }
  }
  .active {
    border-bottom: solid 2px $preto;
  }
  .content {
    border-bottom: solid 2px $preto;
  }
  .footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-right: 60px;
    position: relative;
    margin-top: 8px;
    h5 {
      font-family: Montserrat;
      font-weight: 700;
      color: $preto;
      font-size: 13px;
      span {
        margin-left: 12px;
        font-family: "Roboto Mono", monospace;
      }
    }
  }
}
</style>
