<template>
  <section v-loading="invoiceDialogLoading">
    <el-row :gutter="10">
      <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24" class="card-column">
        <el-card v-loading="loadingStatementsTotals" shadow="hover">
          <div slot="header" class="clearfix">
            <span class="card-title">Total</span>
            <el-popover
              placement="bottom"
              title="Valor total"
              width="200"
              trigger="hover"
              content="Esse é o valor total que você deveria ter recebido entre esse intervalo de data."
            >
              <el-button style="float: right; padding: 5px" slot="reference">Saiba mais</el-button>
            </el-popover>
          </div>
          <p class="card-paragraph card-paragraph-date">
            De
            <b>{{formatDate(this.rangeDates.start)}}</b> até
            <b>{{formatDate(this.rangeDates.end)}}</b>
          </p>
          <p
            class="card-paragraph card-paragraph-value"
          >{{ statementsTotals.total ? formatMoney(statementsTotals.total) : ""}}</p>
        </el-card>
      </el-col>
      <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24" class="card-column">
        <el-card v-loading="loadingStatementsTotals" shadow="hover">
          <div slot="header" class="clearfix">
            <span class="card-title">Pago</span>
            <el-popover
              placement="bottom"
              title="Valor pago"
              width="200"
              trigger="hover"
              content="Esse é o valor que você recebeu entre esse intervalo de data."
            >
              <el-button style="float: right; padding: 5px" slot="reference">Saiba mais</el-button>
            </el-popover>
          </div>
          <p class="card-paragraph card-paragraph-date">
            De
            <b>{{formatDate(this.rangeDates.start)}}</b> até
            <b>{{formatDate(this.rangeDates.end)}}</b>
          </p>
          <p
            class="card-paragraph card-paragraph-value"
          >{{ statementsTotals.paid_amount ? formatMoney(statementsTotals.paid_amount) : ""}}</p>
        </el-card>
      </el-col>
      <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24" class="card-column">
        <el-card v-loading="loadingStatementsTotals" shadow="hover">
          <div slot="header" class="clearfix">
            <span class="card-title" :class="{warningColor: warningTotal}">Restante</span>
            <font-awesome-icon
              v-show="warningTotal"
              icon="exclamation-circle"
              size="lg"
              style="color: #ffd16e;"
            ></font-awesome-icon>
            <el-popover
              placement="bottom"
              title="Valor restante"
              width="200"
              trigger="hover"
              :content="!warningTotal ? 'Esse é o valor que ficou faltando você receber entre esse intervalo de data.' : 'O período que você escolheu ultrapassa a data de hoje, então o valor mostrado nessa caixa é uma previsão!'"
            >
              <el-button
                :class="{warningColor: warningTotal}"
                style="float: right; padding: 5px"
                slot="reference"
              >Saiba mais</el-button>
            </el-popover>
          </div>
          <p class="card-paragraph card-paragraph-date">
            De
            <b>{{formatDate(this.rangeDates.start)}}</b> até
            <b>{{formatDate(this.rangeDates.end)}}</b>
          </p>
          <p
            class="card-paragraph card-paragraph-value"
            :class="{warningColor: warningTotal}"
          >{{statementsTotals.remaining ? formatMoney(statementsTotals.remaining) : ""}}</p>
        </el-card>
      </el-col>
    </el-row>
    <el-input
      v-model="clientQuery"
      :autofocus="true"
      size="mini"
      name="client-search"
      auto-complete="false"
      aria-autocomplete="false"
      placeholder="Buscar"
      style="margin-top: 1rem;"
    >
      <template slot="prepend">Cliente:</template>
    </el-input>
    <el-table
      v-if="!$isMobile"
      v-loading="loadingReports"
      :summary-method="computeSummary"
      :data="statements.data"
      @sort-change="handleStatementsSort"
      style="margin-top: 1rem;"
    >
      <el-table-column
        :formatter="(row, column) => $d(new Date(row.due_date + $tz), 'sale_summary')"
        sortable
        prop="due_date"
        label="Venc."
        align="center"
        min-width="100"
      />
      <el-table-column sortable prop="user" label="Cliente" min-width="150">
        <template slot-scope="scope">
          <span
            class="name"
            @click="handleClientNavigation(scope.row.user.id)"
          >{{ scope.row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :formatter="(row, column) => $n(row.total_remaining, 'currency')"
        prop="total_remaining"
        align="center"
        label="Total"
      />
      <el-table-column
        :formatter="(row, column) => $n(row.paid_amount, 'currency')"
        prop="paid_amount"
        align="center"
        label="Pago"
        min-width="120"
      />
      <el-table-column
        :formatter="(row, column) => $n(row.remaining_amount, 'currency')"
        prop="remaining_amount"
        align="center"
        label="Restante"
        min-width="100"
      />
      <el-table-column label="Ações">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button size="mini">Ações</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  style="width: 100%;"
                  :loading="fetchingStatements"
                  size="mini"
                  icon="el-icon-plus"
                  type="success"
                  @click="showStatementDetails(scope.row.id, scope.row.user.id)"
                >Detalhes</el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  style="width: 100%;"
                  v-if="scope.row.billets.length"
                  size="mini"
                  icon="el-icon-tickets"
                  type="secondary"
                  @click="showBillets(scope.row.billets)"
                >Boletos</el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <section class="custom-card-grid" v-else>
      <el-card v-for="report in statements.data" :key="report.id">
        <div slot="header">
          <h2
            class="card-name"
            @click="handleClientNavigation(report.user.id)"
          >{{ report.user.name }}</h2>
        </div>
        <p class="card-info">
          Data:
          <b>{{$d(new Date(report.due_date + $tz), 'sale_summary')}}</b>
        </p>
        <p class="card-info">
          Total:
          <b>{{$n(report.total_remaining, 'currency')}}</b>
        </p>
        <p class="card-info">
          Pago:
          <b>{{$n(report.paid_amount, 'currency')}}</b>
        </p>
        <p class="card-info">
          Restante:
          <b>{{$n(report.remaining_amount, 'currency')}}</b>
        </p>
        <div class="custom-button-grid">
          <el-button
            size="mini"
            icon="el-icon-plus"
            type="success"
            @click="showStatementDetails(report.id, report.type)"
          >Detalhes</el-button>
          <el-button
            v-if="report.billets.length"
            size="mini"
            icon="el-icon-tickets"
            @click="showBillets(report.billets)"
          >Ver Recibo</el-button>
        </div>
      </el-card>
    </section>
    <div style="width: 100%; display: flex; justify-content: flex-start; margin-top: 16px;">
      <el-pagination
        :total="statements.total_pages * 10"
        :page-sizes="[10, 20, 30, 40, 50]"
        :current-page.sync="statementsPage"
        background
        layout="sizes, prev, pager, next"
        @current-change="handleStatementsPagination"
        @size-change="handleStatementsPaginationSizeChange"
      />
    </div>
    <el-dialog :fullscreen="$isMobile" :visible.sync="showInvoiceDialog">
      <section v-if="invoiceDetails" class="collapse-header">
        <h2>FATURA</h2>
        <div>
          <div class="wrapper">
            <span>Status</span>
            <span
              class="content"
            >{{ invoiceDetails.status ? formatStatus(invoiceDetails.status) : '' }}</span>
          </div>
          <div class="wrapper">
            <span>Data de vencimento</span>
            <span
              class="content"
            >{{ invoiceDetails.due_date ? formatDate(invoiceDetails.due_date) : '' }}</span>
          </div>
          <div class="wrapper">
            <span>Total à pagar</span>
            <span class="content">{{ $n(invoiceDetails.total_remaining_amount, 'currency') }}</span>
          </div>
        </div>
      </section>
      <payment-list :data="invoiceDetails" :has-actions="false" />
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import toMoney from "@/helpers/toMoney";
import moment from "moment";
import PaymentList from "@/components/Payment/PaymentSelector";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

library.add(faExclamationCircle);

export default {
  components: {
    PaymentList,
    FontAwesomeIcon
  },
  props: {
    rangeDates: {
      type: Object,
      default: () => ({})
    },
    computeSummary: {
      type: Function,
      default: () => ({})
    },
    formatDate: {
      type: Function,
      default: () => ({})
    },
    handleClientNavigation: {
      type: Function,
      default: () => ({})
    }
  },
  data() {
    return {
      loadingReports: true,
      fetchingStatements: false,
      showInvoiceDialog: false,
      invoiceDialogLoading: false,
      clientQuery: "",
      statementsPage: 1,
      statementesPageSize: 10,
      loadingStatementsTotals: false,
      warningTotal: false,
      showBilletsDialog: false,
      activeBillets: [],
    };
  },
  computed: {
    ...mapGetters({
      auth_details: types.AUTH_ACTIVE_STORE_DETAILS,
      invoiceDetails: types.CLIENT_INVOICE_DETAILS,
      statements: types.REPORTS_STATEMENTS,
      statementsTotals: types.REPORTS_STATEMENTS_TOTALS,
      store_id: types.AUTH_ACTIVE_STORE
    })
  },
  watch: {
    clientQuery: function(val) {
      this.filteredReports(val);
      this.$emit("textQuery", val);
    },
    rangeDates: async function(val) {
      this.loadingStatementsTotals = true;
      try {
        await this.$store.dispatch(types.REPORTS_STATEMENTS_TOTALS, {
          storeID: this.store_id,
          start_date: this.rangeDates.start.toISOString(),
          end_date: this.rangeDates.end.toISOString(),
          q: this.clientQuery
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loadingStatementsTotals = false;
      if (val.start > new Date() || val.end > new Date()) {
        this.warningTotal = true;
      } else {
        this.warningTotal = false;
      }
    }
  },
  async created() {
    const today = new Date();
    const start = new Date(today.setHours(0, 0, 0));
    const end = new Date(today.setHours(23, 59, 59));
    const page_size = 10;
    this.loadingReports = true;
    try {
      await this.$store.dispatch(types.REPORTS_STATEMENTS, {
        start,
        end,
        page_size
      });
      this.loadingStatementsTotals = true;
      await this.$store.dispatch(types.REPORTS_STATEMENTS_TOTALS, {
        storeID: this.store_id,
        start_date: start.toISOString(),
        end_date: end.toISOString(),
        q: this.clientQuery
      });
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({
        title: "Ops...",
        message: "Não foi possível recuperar as faturas dos clientes."
      });
    }
    this.loadingStatementsTotals = false;
    this.loadingReports = false;
  },
  methods: {
    filteredReports: _.debounce(async function(val) {
      const { start, end } = this.rangeDates;
      this.loadingReports = true;
      try {
        await this.$store.dispatch(types.REPORTS_STATEMENTS, {
          start,
          end,
          q: val
        });
        this.loadingStatementsTotals = true;
        await this.$store.dispatch(types.REPORTS_STATEMENTS_TOTALS, {
          storeID: this.store_id,
          start_date: start.toISOString(),
          end_date: end.toISOString(),
          q: val
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loadingStatementsTotals = false;
      this.loadingReports = false;
    }, 500),
    async handleStatementsSort({ prop, order }) {
      this.loadingReports = true;
      const { start, end } = this.rangeDates;
      let order_by = "";
      switch (prop) {
        case "date":
          order_by = "date_";
          break;
        case "user":
          order_by = "name_";
          break;
      }
      order_by += order === "ascending" ? "asc" : "desc";
      await this.$store
        .dispatch(types.REPORTS_STATEMENTS, {
          start,
          end,
          order_by,
          q: this.clientQuery
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({
            title: "Ops...",
            message: "Não foi possível recuperar as faturas dos clientes."
          });
        });
      this.loadingReports = false;
    },
    async showStatementDetails(id) {
      this.invoiceDialogLoading = true;
      try {
        await this.$store
          .dispatch(types.CLIENT_INVOICE_DETAILS, id)
          .catch(error => {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          });
        this.showInvoiceDialog = true;
        this.$nextTick(() => {
          this.statementDialog = true;
        });
      } catch (error) {
        this.$notify.error("Não foi possível pegar as informações ");
      }
      this.invoiceDialogLoading = false;
    },
    async handleStatementsPagination(page) {
      const { start, end } = this.rangeDates;
      this.loadingReports = true;
      try {
        await this.$store.dispatch(types.REPORTS_STATEMENTS, {
          page,
          start,
          end,
          page_size: this.statementesPageSize,
          q: this.clientQuery
        });
      } catch {
        error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        };
      }
      this.loadingReports = false;
    },
    async handleStatementsPaginationSizeChange(size) {
      const { start, end } = this.rangeDates;
      this.loadingReports = true;
      this.statementesPageSize = size;
      try {
        await this.$store.dispatch(types.REPORTS_STATEMENTS, {
          start,
          end,
          page_size: this.statementesPageSize,
          q: this.clientQuery
        });
      } catch {
        error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        };
      }
      this.loadingReports = false;
    },
    showBillets(billets) {
      this.showBilletsDialog = true;
      this.activeBillets = billets;
    },
    formatMoney(amount) {
      return toMoney(amount);
    },
    handleLoadingStatementsTotals(val) {
      this.loadingStatementsTotals = val;
    },
    formatStatus(status) {
      switch (status) {
        case "open":
          return "Aberta";
          break;
        case "closed":
          return "Fechada";
          break;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.card-title {
  color: $laranja;
  font-weight: bold;
}
.card-paragraph {
  &:first-of-type {
    margin-top: 0;
  }
  margin-top: 1rem;
  &-value {
    font-size: 1.5rem;
    font-weight: bold;
  }
  &-date {
    font-size: 0.8rem;
  }
}
.card-column {
  @media screen and (max-width: 992px) {
    margin-top: 1rem;
  }
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.warningColor {
  color: $amarelo;
}

.card-name {
  font-weight: bold;
  color: $laranja;
  text-align: center;
}
.custom-card-grid {
  margin-top: 1rem;
  display: grid;
  grid: 1fr / 1fr 1fr;
  @media screen and (max-width: 527px) {
    grid: 1fr / 1fr;
  }
  grid-gap: 10px;

  .card-info {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $preto;
    margin-top: 5px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  .custom-button-grid {
    display: grid;
    grid: 1fr 1fr 1fr / 1fr;
    grid-gap: 5px;
    margin-top: 1rem;
    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
.el-pagination {
  @media screen and (max-width: 767px) {
    white-space: normal;
  }
}
</style>
