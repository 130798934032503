<template>
  <sales-reports />
</template>

<script>
import SalesReports from "@/containers/Reports/SalesReports";
export default {
  components: {
    SalesReports
  }
};
</script>
