<template>
	<section>
		<el-input
			v-model="clientQuery"
			:autofocus="true"
			size="mini"
			name="client-search"
			auto-complete="false"
			aria-autocomplete="false"
			placeholder="Buscar"
		>
			<template slot="prepend">Cliente:</template>
		</el-input>
		<el-table
			v-if="!$isMobile"
			v-loading="loadingReports"
			:summary-method="computeSummary"
			:data="reportsDeals.data"
			show-summary
			sum-text="Total"
			@sort-change="handleReportSort"
		>
			<el-table-column sortable prop="identifier" label="#" align="center" />
			<el-table-column
				:formatter="(row) => formatDate(row.date)"
				sortable
				prop="date"
				label="Data"
				align="center"
			/>
			<el-table-column
				:formatter="(row, column) => $n(row.down_payments, 'currency')"
				sortable
				prop="down_payments"
				align="center"
				label="Entrada"
				min-width="120"
			 />
			<el-table-column
				:formatter="(row, column) => $n(row.amount, 'currency')"
				sortable
				prop="amount"
				align="center"
				label="Valor"
				min-width="120"
			/>
			<el-table-column sortable prop="user" label="Cliente" align="center" min-width="150">
				<template slot-scope="scope">
					<span
						class="name"
						@click="handleClientNavigation(scope.row.user.id)"
					>{{ scope.row.user.name }}</span>
				</template>
			</el-table-column>
			<el-table-column sortable prop="branch" align="center" label="Operador" min-width="120">
				<template slot-scope="scope">
					<span class="name">{{ scope.row.operator && scope.row.operator.name }}</span>
				</template>
			</el-table-column>
			<el-table-column sortable prop="status" align="center" label="Status" min-width="140">
				<template slot-scope="scope">
					<el-tag
						size="small"
						:type="handleStatusColor(scope.row.active)"
					>{{scope.row.active ? 'Ativo(a)' : 'Cancelado(a)'}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column align="center" label="Ações" min-width="100">
				<template slot-scope="scope">
					<el-dropdown trigger="click">
						<el-button size="mini">Ações</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>
								<el-button
									style="width: 100%;"
									size="mini"
									icon="el-icon-plus"
									type="success"
									@click="showDealDetails(scope.row.id, scope.row.type)"
								>Detalhes</el-button>
							</el-dropdown-item>
							<el-dropdown-item>
								<el-button
									style="width: 100%;"
									size="mini"
									icon="el-icon-plus"
									@click="showReceipt(scope.row.id, scope.row.type, scope.row.user.id)"
								>Ver Recibo</el-button>
							</el-dropdown-item>
							<el-dropdown-item>
								<!-- <el-button
									style="width: 100%;"
									:disabled="scope.row.payment && scope.row.payment.payment_type === 'billet' || auth_details.permissions && !auth_details.permissions.cancel_sale || !scope.row.active"
									size="mini"
									icon="el-icon-delete"
									type="danger"
									@click="confirmCancel(scope.row.id, scope.row.type)"
								>Cancelar</el-button> -->
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</template>
			</el-table-column>
		</el-table>
		<section class="custom-card-grid" v-else>
			<el-card v-for="report in reportsDeals.data" :key="report.id">
				<div slot="header">
					<h2
						class="card-name"
						@click="handleClientNavigation(report.user.id)"
					>{{ report.user.name }}</h2>
				</div>
				<p class="card-info">
					#:
					<b>{{report.identifier}}</b>
				</p>
				<p class="card-info">
					Data:
					<b>{{formatDate(report.date)}}</b>
				</p>
				<p class="card-info">
					Valor:
					<b>{{$n(report.amount, 'currency')}}</b>
				</p>
				<p class="card-info">
					Operador:
					<b>{{report.operator && report.operator.name}}</b>
				</p>
				<p class="card-info">
					Status:
					<b>{{report.active ? 'Ativo(a)' : 'Cancelado(a)'}}</b>
				</p>
				<div class="custom-button-grid">
					<el-button
						size="mini"
						icon="el-icon-plus"
						type="success"
						@click="showDealDetails(report.id, report.type)"
					>Detalhes</el-button>
					<el-button
						size="mini"
						icon="el-icon-plus"
						@click="showReceipt(report.id, report.type, report.user.id)"
					>Ver Recibo</el-button>
					<el-button
						:disabled="report.payment && report.payment.payment_type === 'billet' || auth_details.permissions && !auth_details.permissions.cancel_sale || !report.active"
						size="mini"
						icon="el-icon-delete"
						type="danger"
						@click="confirmCancel(report.id, report.type)"
					>Cancelar</el-button>
				</div>
			</el-card>
		</section>
		<div style="width: 100%; display: flex; justify-content: flex-start; margin-top: 16px;">
			<el-pagination
				:total="reportsDeals.total_pages * 10"
				:page-sizes="[10,20,30,40,50]"
				:current-page.sync="reportsPage"
				background
				layout="sizes,prev, pager, next"
				@current-change="handlePaymentsReportsPagination"
				@size-change="handleDealsReportsSizeChange"
			/>
		</div>
		<el-dialog :fullscreen="$isMobile" :visible.sync="showDealDialog">
			<section v-if="saleDetails" class="collapse-header">
				<h2>REPARCELAMENTO</h2>
				<div>
					<div class="wrapper">
						<span>Data da venda</span>
						<span
							class="content"
						>{{ saleDetails.sale_date ? $d(new Date(saleDetails.sale_date), 'sale_summary') : '' }}</span>
					</div>
					<div class="wrapper">
						<span>Total</span>
						<span class="content">{{ $n(saleDetails.amount, 'currency') }}</span>
					</div>
				</div>
			</section>
			<sale-selector :data="saleDetails" :has-actions="false" />
		</el-dialog>
		<el-dialog :fullscreen="$isMobile" :visible.sync="showInvoiceDialog">
			<section v-if="invoiceDetails" class="collapse-header">
				<h2>FATURA</h2>
				<div>
					<div class="wrapper">
						<span>Data de vencimento</span>
						<span
							class="content"
						>{{ invoiceDetails.due_date ? formatDate(invoiceDetails.due_date) : '' }}</span>
					</div>
					<div class="wrapper">
						<span>Total à pagar</span>
						<span class="content">{{ $n(invoiceDetails.total_remaining_amount, 'currency') }}</span>
					</div>
				</div>
			</section>
			<payment-list :data="invoiceDetails" :has-actions="false" />
		</el-dialog>
		<el-dialog :fullscreen="$isMobile" :visible.sync="receiptDialog">
			<section v-if="receiptDetails" class="collapse-header">
				<h2>RECEBIMENTO</h2>
				<div>
					<div class="wrapper">
						<span>Data do Pagamento</span>
						<span
							class="content"
						>{{ receiptDetails.payment_date ? formatDate(receiptDetails.payment_date) : '' }}</span>
					</div>
					<div class="wrapper">
						<span>Total pago</span>
						<span class="content">{{ $n(receiptDetails.amount, 'currency') }}</span>
					</div>
				</div>
			</section>
			<receipt-list :data="receiptDetails" :has-actions="false" />
		</el-dialog>
		<el-dialog :visible.sync="showConfirmationDialog">
			<div style="text-align: center;">
				<h3>Tem certeza que deseja cancelar {{ toCancelType === 'sale_deal' ? 'esse reparcelamento' : 'esse recebimento' }}?</h3>
				<br />
				<el-button type="danger" @click="handleDealCancel">Sim, quero cancelar</el-button>
				<el-button type="secondary" @click="showConfirmationDialog = false">Cancelar</el-button>
			</div>
		</el-dialog>
	</section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import _ from "lodash";
import SaleSelector from "@/components/Sale/SaleSelector";
import PaymentList from "@/components/Payment/PaymentSelector";
import ReceiptList from "@/components/Receipt/ReceiptSelector";

export default {
	components: {
		SaleSelector,
		PaymentList,
		ReceiptList
	},
	props: {
		rangeDates: {
			type: Object,
			default: () => ({})
		},
		computeSummary: {
			type: Function,
			default: () => ({})
		},
		handleReportSort: {
			type: Function,
			default: () => ({})
		},
		handleTypes: {
			type: Function,
			default: () => ({})
		},
		formatDate: {
			type: Function,
			default: () => ({})
		},
		showReceipt: {
			type: Function,
			default: () => ({})
		},
		handleClientNavigation: {
			type: Function,
			default: () => ({})
		}
	},
	data() {
		return {
			showDealDialog: false,
			showInvoiceDialog: false,
			receiptDialog: false,
			showConfirmationDialog: false,
			toCancelType: undefined,
			loadingReports: true,
			clientQuery: "",
			width: null,
			reportsPage: 1,
			dealReportsPageSize: 10
		};
	},
	computed: {
		...mapGetters({
			reportsDeals: types.REPORTS_DEALS,
			saleDetails: types.SALE_MORE_DETAILS,
			receiptDetails: types.PAYMENT_MORE_DETAILS,
			auth_details: types.AUTH_ACTIVE_STORE_DETAILS,
			invoiceDetails: types.CLIENT_INVOICE_DETAILS
		})
	},
	watch: {
		clientQuery: function(val) {
			this.filteredReports(val);
			this.$emit("textQuery", val);
		}
	},
	async created() {
		const today = new Date();
		const start = new Date(today.setHours(0, 0, 0));
		const end = new Date(today.setHours(23, 59, 59));
		// this.rangeDates = { start, end };
		const page_size = 10;
		this.loadingReports = true;
		try {
			await this.$store.dispatch(types.REPORTS_DEALS, {
				start,
				end,
				page_size
			});
		} catch (error) {
			if (this.$raven && error.status === 500) {
				this.$raven.captureException(error.message, { req: error });
			}
			this.$notify.error({
				title: "Ops...",
				message: "Não foi possível recuperar os relatórios."
			});
		}
		this.loadingReports = false;
	},
	methods: {
		async handlePaymentsReportsPagination(page) {
			const { start, end } = this.rangeDates;
			this.loadingReports = true;
			try {
				await this.$store.dispatch(types.REPORTS_DEALS, {
					page,
					page_size: this.dealReportsPageSize,
					start,
					end,
					q: this.clientQuery
				});
			} catch {
				error => {
					if (this.$raven && error.status === 500) {
						this.$raven.captureException(error.message, { req: error });
					}
					this.$notify.error({ title: error.message });
				};
			}
			this.loadingReports = false;
		},
		confirmCancel(id, type) {
			this.showConfirmationDialog = true;
			this.toCancelID = id;
			this.toCancelType = type;
		},
		async handleDealCancel() {
			this.loadingReports = true;
			const action =
				this.toCancelType === "sale_deal"
					? types.SALE_CANCEL
					: this.toCancelType === "payment"
					? types.PAYMENT_CANCEL
					: false;
			if (action) {
				await this.$store.dispatch(action, this.toCancelID);
			}
			this.toCancelID = undefined;
			this.toCancelType = undefined;
			this.showConfirmationDialog = false;
			this.loadingReports = false;
		},
		async showDealDetails(id, type, types) {
			try {
				const action = types.SALE_MORE_DETAILS;
				await this.$store.dispatch(action, id);
				if (type === "sale_deal") {
					this.$nextTick(() => {
						this.showDealDialog = true;
					});
				}
			} catch (error) {
				if (this.$raven && error.status === 500) {
					this.$raven.captureException(error.message, { req: error });
				}
				this.$notify.error({ title: error.message });
			}
		},
		filteredReports: _.debounce(async function(val) {
			const { start, end } = this.rangeDates;
			try {
				await this.$store.dispatch(types.REPORTS_DEALS, {
					start,
					end,
					q: val
				});
			} catch (error) {
				if (this.$raven && error.status === 500) {
					this.$raven.captureException(error.message, { req: error });
				}
				this.$notify.error({ title: error.message });
			}
		}, 500),
		async handleDealsReportsSizeChange(size) {
			const { start, end } = this.rangeDates;
			this.loadingReports = true;
			this.dealReportsPageSize = size;
			try {
				await this.$store.dispatch(types.REPORTS_DEALS, {
					start,
					end,
					page_size: this.dealReportsPageSize,
					q: this.clientQuery
				});
				this.$emit("onDealChangePageSize", size);
			} catch {
				error => {
					if (this.$raven && error.status === 500) {
						this.$raven.captureException(error.message, { req: error });
					}
					this.$notify.error({ title: error.message });
				};
			}
			this.loadingReports = false;
		},
		handleStatusColor(status) {
			const type = {
				true: "success",
				false: "danger"
			};
			return type[status];
		}
	}
};
</script>

<style lang="scss" scoped>
.el-tag {
	width: 100px;
}
.card-name {
	font-weight: bold;
	color: $laranja;
	text-align: center;
}
.custom-card-grid {
	margin-top: 1rem;
	display: grid;
	grid: 1fr / 1fr 1fr;
	@media screen and (max-width: 527px) {
		grid: 1fr / 1fr;
	}
	grid-gap: 10px;

	.card-info {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $preto;
		margin-top: 5px;
		&:first-of-type {
			margin-top: 0;
		}
	}
	.custom-button-grid {
		display: grid;
		grid: 1fr 1fr 1fr / 1fr;
		grid-gap: 5px;
		margin-top: 1rem;
		.el-button + .el-button {
			margin-left: 0;
		}
	}
}
.el-pagination {
	@media screen and (max-width: 767px) {
		white-space: normal;
	}
}
</style>
