<template>
  <div style="margin-bottom: 1rem;">
    <div class="custom-select">
      <el-select
        v-if="
          staff.role === 'owner' && action != 'faturas' && action != 'billets'
        "
        v-model="branchFilters"
        placeholder="Selecione uma unidade"
        size="small"
        style="margin-bottom: 1rem;"
        @change="handleBranchChange"
      >
        <el-option label="Todas as unidades" value="all" />
        <el-option v-for="item in branchList" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
    </div>
    <div class="desktop-filters">
      <el-radio-group v-model="currentRangeLabel" size="small">
        <el-radio-button label="Hoje" type="secondary" />
        <el-radio-button label="Ontem" type="secondary" />
        <el-radio-button label="7 dias" type="secondary" />
        <el-radio-button label="15 dias" type="secondary" />
      </el-radio-group>
      <el-date-picker
        ref="datePicker"
        v-model="datePickerRange"
        :default-time="['00:00:00', '23:59:59']"
        size="small"
        type="daterange"
        format="dd/MM/yyyy"
        range-separator="até"
        start-placeholder="Início"
        end-placeholder="Fim"
        align="center"
        @change="filterReports(true)"
      />
      <el-button
        v-if="showDownload"
        :loading="downloading"
        class="csv"
        size="small"
        icon="el-icon-download"
        type="secondary"
        @click="downloadReports('csv')"
      >CSV</el-button>
      <el-button
        v-if="showDownload"
        :loading="downloading"
        class="xlsx"
        size="small"
        icon="el-icon-download"
        type="secondary"
        @click="downloadReports('xlsx')"
      >XLSX</el-button>
    </div>
    <h4 v-if="action === 'venda' || action === 'recebimento'" style="margin-bottom: 8px;">Status</h4>
    <el-radio-group
      v-if="action === 'venda' || action === 'recebimento'"
      v-model="salePaymentStatus"
      size="small"
      @change="handleSalePaymentStatusChange"
    >
      <el-radio-button label="all" type="secondary">Todos</el-radio-button>
      <el-radio-button label="active" type="secondary">Ativas</el-radio-button>
      <el-radio-button label="canceled" type="secondary">Canceladas</el-radio-button>
    </el-radio-group>
    <h4 v-if="showBilletsFilters" style="margin-bottom: 8px;">Status do boleto</h4>
    <el-switch
      v-if="showBilletsFilters"
      v-model="billetsFilterType"
      active-text="Data de pagamento"
      inactive-text="Data de vencimento"
    ></el-switch>
    <!-- eslint-disable-next-line vue/no-parsing-error -->
    <br />
    <el-radio-group
      style="margin-top: 1rem;"
      v-if="showBilletsFilters"
      v-model="billetsStatus"
      size="mini"
      @change="status => handleBilletStatusChange(status)"
    >
      <el-radio-button :disabled="billetsFilterType" label="all">Todos</el-radio-button>
      <el-radio-button :disabled="billetsFilterType" label="registered">Registrado</el-radio-button>
      <el-radio-button label="paid">Pago</el-radio-button>
      <el-radio-button :disabled="billetsFilterType" label="canceled">Cancelado</el-radio-button>
      <el-radio-button :disabled="billetsFilterType" label="expired">Expirado</el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    showDownload: {
      type: Boolean,
      default: false
    },
    showBilletsFilters: {
      type: Boolean,
      default: false
    },
    billetsData: {
      type: Object,
      default: () => {}
    },
    reportsPage: {
      type: Number,
      default: 1
    },
    query: {
      type: String,
      default: ""
    },
    pageSize: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      salePaymentStatus: "all",
      branchFilters: "all",
      downloading: false,
      datePickerRange: [],
      currentRangeLabel: "Hoje",
      billetsStatus: "all",
      billetsFilterType: false
    };
  },
  computed: {
    ...mapGetters({
      branchList: types.BRANCH_LIST,
      auth: types.AUTH,
      store_id: types.AUTH_ACTIVE_STORE,
      activeBranch: types.AUTH_ACTIVE_BRANCH_DETAILS,
      user: types.AUTH_USER,
      staff: types.STAFF_ACTIVE
    }),
    range() {
      if (!this.currentRangeLabel && this.datePickerRange)
        return this.datePickerRange;
      const today = new Date();
      const rangeEnd = new Date(today.setHours(23, 59, 59));
      const rangeStart = new Date(today.setHours(0, 0, 0));
      const number = +this.currentRangeLabel.replace(/ dias/g, "");

      if (this.currentRangeLabel === "Hoje") {
        return [rangeStart, rangeEnd];
      }
      if (number) {
        rangeStart.setTime(rangeStart.getTime() - 3600 * 1000 * 24 * number);
        return [rangeStart, rangeEnd];
      }

      rangeStart.setTime(rangeStart.getTime() - 3600 * 1000 * 24);

      return [
        new Date(rangeStart.setHours(0, 0, 0)),
        new Date(rangeStart.setHours(23, 59, 59))
      ];
    }
  },
  watch: {
    reportsPage: function() {
      this.filterReports();
    },
    range: function() {
      this.filterReports();
      if (this.currentRangeLabel) {
        this.datePickerRange = this.range;
      }
    },
    action: function() {
      this.filterReports();
    },
    showBilletsFilters: function() {
      this.billetsStatus = "all";
      this.$emit("onBilletStatusChange", "all");
    },
    billetsFilterType: function(val) {
      if (val) {
        this.billetsStatus = "paid";
      } else {
        this.billetsStatus = "all";
      }
      this.handleBilletsFilterType(val);
      this.$emit("onBilletsFilterType", val);
    }
  },
  async mounted() {
    try {
      const foundStore = await this.user.stores.find(
        store => store.id === this.store_id
      );
      await this.$store.dispatch(types.STAFF_ACTIVE, {
        storeID: this.store_id,
        userID: this.user.id
      });
      if (foundStore && foundStore.role === "owner") {
        await this.$store.dispatch(types.BRANCH_LIST, {
          storeID: this.store_id
        });
      } else {
        await this.$store.dispatch(types.BRANCH_LIST, {
          storeID: this.store_id,
          userID: this.user.id
        });
      }
      this.handleBranchChange(null);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.salePaymentStatus = "all";
    this.branchFilters = "all";
    this.billetsStatus = "all";
    this.datePickerRange = this.range;
    this.$emit("onBilletStatusChange", "all");
  },
  methods: {
    async handleSalePaymentStatusChange(status) {
      const payload = {
        start: this.range[0],
        end: this.range[1],
        status,
        page: this.reportsPage,
        page_size: this.pageSize,
        q: this.query
      };

      if (this.action === "recebimento") {
        payload.type = "payments";
        this.$emit("update:loading", true);
        await this.$store.dispatch(types.REPORTS_PAYMENTS, payload);
        this.$emit("update:loading", false);
      }
      if (this.action === "venda") {
        payload.type = "sales";
        this.$emit("update:loading", true);
        await this.$store.dispatch(types.REPORTS, payload);
        this.$emit("update:loading", false);
      }
      if (this.action === "acordo") {
        payload.type = "deals";
        this.$emit("update:loading", true);
        await this.$store.dispatch(types.REPORTS_DEALS, payload);
        this.$emit("update:loading", false);
      }
    },
    async handleBilletStatusChange(status) {
      this.$emit("update:loading", true);
      const billetsFilterTypeDate = false;
      this.$emit("onBilletStatusChange", status);
      await this.$store
        .dispatch(types.REPORTS_BILLETS, {
          store_id: this.store_id,
          start: this.range[0],
          end: this.range[1],
          page: this.reportsPage,
          page_size: this.pageSize,
          status,
          billetsFilterTypeDate
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({
            title: "Ops...",
            message: "Não foi possível recuperar os relatorios de boletos."
          });
        });
      this.$emit("update:loading", false);
    },
    async handleBilletsFilterType(val) {
      this.billetsFilterType = val;
      this.$emit("update:loading", true);
      const billetsFilterTypeDate = this.billetsFilterType;
      const type = this.billetsFilterType;
      await this.$store
        .dispatch(types.REPORTS_BILLETS, {
          store_id: this.store_id,
          start: this.range[0],
          end: this.range[1],
          billetsFilterTypeDate
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({
            title: "Ops...",
            message: "Não foi possível recuperar os relatorios de boletos."
          });
        });
      this.$emit("update:loading", false);
    },
    async handleBranchChange(branchId) {
      this.$emit("update:loading", true);
      if (branchId !== "all") {
        await this.$store.dispatch(types.AUTH_ACTIVE_BRANCH, branchId);
      } else {
        await this.$store.dispatch(types.AUTH_ACTIVE_BRANCH, null);
      }
      let actionType = "";
      switch (this.action) {
        case "venda":
          actionType = types.REPORTS;
          break;
        case "recebimento":
          actionType = types.REPORTS_PAYMENTS;
          break;
        case "acordo":
          actionType = types.REPORTS_DEALS;
          break;
        case "faturas":
          actionType = types.REPORTS_STATEMENTS;
          break;
        case "billets":
          actionType = types.REPORTS_BILLETS;
          break;
      }
      const payload = {};
      payload.branch = this.branchFilters;
      if (this.datePickerRange) {
        payload.start = this.datePickerRange[0];
        payload.end = this.datePickerRange[1];
        payload.page = this.reportsPage;
      }
      if (this.action === "billets") {
        payload.start_date = this.datePickerRange[0].toISOString();
        payload.end_date = this.datePickerRange[1].toISOString();
        payload.store_id = this.billetsData.storeId;
        payload.page = this.billetsData.page;
        payload.status = this.billetsStatus;
      }

      if (this.action === "recebimento") {
        payload.type = "payments";
      }

      if (this.action === "acordo") {
        payload.type = "deals";
      }

      await this.$store.dispatch(actionType, payload);
      this.$emit("update:loading", false);
    },
    async filterReports(isCustom = false) {
      let actionType = "";
      switch (this.action) {
        case "venda":
          actionType = types.REPORTS;
          break;
        case "recebimento":
          actionType = types.REPORTS_PAYMENTS;
          break;
        case "acordo":
          actionType = types.REPORTS_DEALS;
          break;
        case "faturas":
          actionType = types.REPORTS_STATEMENTS;
          break;
        case "billets":
          actionType = types.REPORTS_BILLETS;
          break;
      }
      if (!this.range) return;
      const payload = {
        start: this.range[0],
        end: this.range[1],
        status: this.salePaymentStatus,
        page: this.reportsPage,
        branch: this.branchFilters,
        q: this.query,
        page_size: this.pageSize
      };
      const dates = {
        start: this.range[0],
        end: this.range[1]
      };

      if (this.action === "billets") {
        payload.status = this.billetsStatus;
        payload.store_id = this.billetsData.storeId;
        payload.billetsFilterTypeDate = this.billetsFilterType;
      }

      if (this.action === "recebimento") {
        payload.type = "payments";
      }
      if (this.action === "acordo") {
        payload.type = "deals";
      }

      if (isCustom && this.datePickerRange) {
        this.currentRangeLabel = "";
        payload.start = this.datePickerRange[0];
        payload.end = this.datePickerRange[1];
      }
      try {
        this.$emit("update:loading", true);
        this.$emit("onChangeDates", dates);
        await this.$store.dispatch(actionType, payload);
        this.$emit("update:loading", false);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async downloadReports(type) {
      let actionType = "";
      switch (this.action) {
        case "venda":
          actionType = types.REPORTS;
          break;
        case "recebimento":
          actionType = types.REPORTS_PAYMENTS;
          break;
        case "acordo":
          actionType = types.REPORTS_DEALS;
          break;
        case "faturas":
          actionType = types.REPORTS_STATEMENTS;
          break;
        case "billets":
          actionType = types.REPORTS_BILLETS;
          break;
      }
      this.downloading = true;
      if (!this.range) return;
      const payload = {};
      payload.branch = this.branchFilters;
      if (this.datePickerRange) {
        payload.start = this.datePickerRange[0];
        payload.end = this.datePickerRange[1];
        payload.page = this.reportsPage;
        payload.q = this.query;
        payload.page_size = this.pageSize;
      }

      payload.customHeader =
        type === "csv"
          ? { accept: "text/csv" }
          : {
              accept:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
            };
      if (this.action === "billets") {
        payload.start = this.datePickerRange[0];
        payload.end = this.datePickerRange[1];
        payload.store_id = this.billetsData.storeId;
        payload.page = this.billetsData.page;
        payload.status = this.billetsStatus;
      }

      if (this.action === "recebimento") {
        payload.type = "payments";
      }
      this.$emit("update:loading", true);
      const response = await this.$store
        .dispatch(actionType, payload)
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.$emit("update:loading", false);
      this.downloading = false;
      if (response.data) {
        this.downloadFile(response.data, type, payload.customHeader);
      }
    },
    downloadFile(data, type, header) {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: header })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `relatorio-neopag.${type}`);
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>

<style lang="scss">
.custom-select {
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
  }
}
.desktop-filters {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  small,
  .el-radio-group {
    flex: 0 auto;
    margin: 0;
    margin-right: 1rem;
  }
  .el-date-editor {
    margin-left: auto;
    margin-right: 1rem;
    .el-range__close-icon {
      position: absolute;
      top: 0;
      right: 0;
    }
    &--datetimerange.el-input,
    &--datetimerange.el-input__inner {
      width: 300px;
    }
    .el-range-separator {
      min-width: 13%;
    }
  }
  &-label {
    color: $preto;
    opacity: 0.75;
  }

  @include small-down {
    margin-top: 1rem;
    justify-content: center;
    .el-radio-group {
      flex: 0 auto;
      margin: 0;
    }
    small {
      margin: 0 19px;
    }
    .el-date-editor {
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-right: 1rem;
      margin-left: 0;
      &--datetimerange.el-input__inner {
        width: 100%;
      }
    }
  }
}

@include small-down {
  .el-date-range-picker__content {
    float: none;
    width: 100%;
  }
  .el-picker-panel {
    top: 194px !important;
    left: 0 !important;
    width: 100% !important;
    min-width: auto !important;
  }
  .el-date-range-picker .el-picker-panel__body {
    min-width: auto;
  }
}
.el-date-table th {
  padding: 0;
}
</style>
