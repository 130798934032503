<template>
  <payments-reports />
</template>

<script>
import PaymentsReports from "@/containers/Reports/PaymentsReports";
export default {
  components: {
    PaymentsReports
  }
};
</script>
