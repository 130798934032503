<template>
  <div class="payment-selector">
    <h4 v-if="data.payments && data.payments.length > 0">PAGAMENTOS</h4>
    <receipt-selector-list
      v-if="data.payments && data.payments.length > 0"
      :data="data.payments"
      :hide-total="true"
    />
  </div>
</template>

<script>
import moment from 'moment';
import toMoney from "@/helpers/toMoney";

import ReceiptSelectorList from './ReceiptSelectorList';

export default {
  components: {
    ReceiptSelectorList,
  },
  props: {
    data: {
      type: [Object, null],
      default: () => {}
    },
    future: {
      type: [Boolean, null],
      default: false,
    },
    deselectedItem: {
      type: [Object, null],
      default: () => null,
    },
    hasActions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedAll: false,
      disableAll: false,
      showModal: false,
      futureItem: null,
      interestItem: null,
      selectedInstallment: [],
    }
  },
  watch: {
    deselectedItem: function(newValue) {
      if (!newValue.id) {
        this.selectedAll = false;
        this.disableAll = false;
      }
    }
  },
  methods: {
    formatTotal(amount) {
      if (amount <= 0) {
        return this.formatMoney(0);
      }
      return this.formatMoney(amount);
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatMoney(amount) {
      return toMoney(amount);
    },
    handleAll() {
      this.selectedAll = true;
      this.disableAll = true;
      this.$emit('onClickAll', this.data);
    },
    handleListClick(data) {
      if (this.future) {
        this.selectedInstallment = data;
        return this.showModal = true;
      }
      this.$emit('onClick', data);
    },
    handleModalClose() {
      this.selectedInstallment = [];
      this.selectedAll = false;
      this.disableAll = false;
      return this.showModal = false;
    },
    handleModalSubmit(data) {
      this.futureItem = data;
      this.$emit('onClick', data);
    },
  }
}
</script>

<style lang="scss">
  .payment-selector {
    h4 {
      font-size: 13px;
      font-family: Montserrat;
      color: $preto;
      font-weight: 700;
      letter-spacing: 1.025px;
      margin: 12px 0;
      padding: 0 4px;
    }
    .statement-total {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      position: relative;
      padding-right: 56.6px;
      h4 {
        font-size: 13px;
        strong {
          font-family: 'Roboto Mono', monospace;
          margin-left: 30px;
          color: $laranja;
          font-size: 13px;
        }
      }
      .selector-button-amount {
        top: 14.6px;
      }
    }
  }
</style>
