<template>
  <div class="relatorio">
    <desktop-filters
      :style="`display: ${(!subscription.allow_billets || !info.create_billets )&& tab === 'billets' ? 'none' : 'block'}`"
      :loading.sync="loadingReports"
      :show-download="true"
      :show-billets-filters="tab === 'billets'"
      :action="tab"
      :query="query"
      :page-size="pageSize"
      :reports-page="reportsPage"
      :billets-data="{ storeId: store_id, page: billetsPage }"
      @onChangeDates="handleChangeDates"
      @onBilletStatusChange="status => changeBilletStatus(status)"
      @onBilletsFilterType="type => changeBilletFilterType(type)"
    />
    <billets-reports
      :billet-status="billetStatus"
      :range-dates="rangeDates"
      :loading="loadingReports"
      :billets-filter-type="billetsFilterType"
      @page="page => (billetsPage = page)"
      @onBilletsChangePageSize="size => sendPageSizeToFilter(size)"
    />
  </div>
</template>

<script>
import DesktopFilters from "@/containers/Reports/DesktopFilters";
import BilletsReports from "@/components/Reports/BilletsReports";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
export default {
  components: {
    DesktopFilters,
    BilletsReports
  },
  data() {
    return {
      loadingReports: true,
      query: "",
      pageSize: 10,
      reportsPage: 1,
      billetsPage: 1,
      billetStatus: "",
      billetsFilterType: false
    };
  },
  computed: {
    ...mapGetters({
      subscription: types.SUBSCRIPTION_CONFIG,
      info: types.STORE_INFO,
      store_id: types.AUTH_ACTIVE_STORE
    })
  },
  async created() {
    this.tab = "billets";
    const today = new Date();
    const start = new Date(today.setHours(0, 0, 0));
    const end = new Date(today.setHours(23, 59, 59));
    this.rangeDates = { start, end };
  },
  methods: {
    handleChangeDates(dates) {
      const { start, end } = dates;
      this.rangeDates = dates;
    },
    changeBilletStatus(status) {
      this.billetStatus = status;
    },
    changeBilletFilterType(type) {
      this.billetsFilterType = type;
    },
    sendPageSizeToFilter(size) {
      this.pageSize = size;
    },
  }
};
</script>

<style lang="scss">
.relatorio {
  position: relative;
  margin: 1rem;
  .el-tabs__item {
    font-family: $title-font;
  }
  .el-table {
    .cell {
      word-wrap: normal;
      word-break: break-word;
    }
  }
  .collapse-header {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    align-items: center;
    padding-right: 14px;
    h2 {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      font-weight: 700;
    }
    .wrapper {
      display: inline-block;
      height: 48px;
      margin: 6px 0 0 12px;
      span {
        font-family: Montserrat;
        letter-spacing: 1.025px;
        text-align: center;
        font-size: 14px;
        display: block;
        line-height: 14px;
        font-weight: 300;
      }
      .content {
        font-family: Montserrat;
        letter-spacing: 1.025px;
        color: $laranja;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
      }
    }
  }
  .el-table {
    width: 100%;
  }
  .el-table__row td .cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .el-table__row td .cell .name {
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: $azul;
      text-decoration: underline;
    }
  }
  .el-table__footer-wrapper td {
    font-size: 15px;
    font-weight: bold !important;
  }
}
</style>
