<template>
	<div class="sale-payment-selector">
		<sale-selector-list
			v-if="data && data.installments_number > 0
			"
			:statement-id="data.id"
			:data="data.installments"
			:total="data.amount"
			:action="false"
			:future="future"
			:future-item="futureItem"
			:all="selectedAll"
			:deselected-item="deselectedItem"
			:previous-condition="data.previous_condition"
		/>
		<!-- <h4 v-if="data.down_payments != [] && data.down_payments.amount != 0 && data.down_payments.length != 0">Pagamentos</h4>
		<sale-payment-selector-list
			v-if="data.down_payments != [] && data.down_payments.amount != 0 && data.down_payments.length != 0"
			:data="data.down_payments"
		/>-->
	</div>
</template>

<script>
import moment from "moment";
import toMoney from "@/helpers/toMoney";

import SaleSelectorList from "./SaleSelectorList";

export default {
	components: {
		SaleSelectorList,
	},
	props: {
		data: {
			type: [Object, null],
			default: () => {}
		},
		future: {
			type: [Boolean, null],
			default: false
		},
		deselectedItem: {
			type: [Object, null],
			default: () => null
		},
		hasActions: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			selectedAll: false,
			disableAll: false,
			showModal: false,
			futureItem: null,
			interestItem: null,
			selectedInstallment: []
		};
	},
	methods: {
		formatTotal(amount) {
			if (amount <= 0) {
				return this.formatMoney(0);
			}
			return this.formatMoney(amount);
		},
		formatDate(date) {
			return moment(date).format("DD/MM/YYYY");
		},
		formatMoney(amount) {
			return toMoney(amount);
		},
		handleModalClose() {
			this.selectedInstallment = [];
			this.selectedAll = false;
			this.disableAll = false;
			return (this.showModal = false);
		}
	}
};
</script>

<style lang="scss">
.sale-payment-selector {
	h4 {
		font-size: 13px;
		font-family: Montserrat;
		color: $preto;
		font-weight: 700;
		letter-spacing: 1.025px;
		margin: 12px 0;
		padding: 0 4px;
	}
	.statement-total {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		position: relative;
		padding-right: 56.6px;
		h4 {
			font-size: 13px;
			strong {
				font-family: "Roboto Mono", monospace;
				margin-left: 30px;
				color: $laranja;
				font-size: 13px;
			}
		}
		.selector-button-amount {
			top: 14.6px;
		}
	}
}
</style>
